import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Box, TextField, Button, Pagination } from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsSpin, faPlus, faHammer } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';

function formatDuration(milliseconds) {
    let remainingMilliseconds = milliseconds;

    const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
    remainingMilliseconds %= 1000 * 60 * 60;

    const minutes = Math.floor(remainingMilliseconds / (1000 * 60));
    remainingMilliseconds %= 1000 * 60;
    return `${hours}h ${minutes}m`;
}

function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const [auth, setAuth] = useState(localStorage.getItem('auth'));
    const [isLoading, setLoading] = useState(auth !== null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [search, setSearch] = useState(location.state !== null && 'search' in location.state ? location.state.search : '');
    const [page, setPage] = useState(location.state !== null && 'page' in location.state ? location.state.page : 1);
    const [data, setData] = useState({
        admin: false,
        count: 0,
        content: []
    });

    useEffect(() => localStorage.setItem('auth', auth), [auth]);

    useEffect(() => {
        window.history.replaceState({}, '');
        let auth = localStorage.getItem('auth');

        if (auth !== null) {
            fetch('/api/auth/refresh/', {
                method: 'POST',
                body: JSON.stringify({
                    auth: auth
                })
            }).then(async response => {
                auth = null;

                if (response.status === 200) {
                    auth = await response.text();
                }

                if (auth === null) {
                    unstable_batchedUpdates(() => {
                        setAuth(auth);
                        setLoading(false);
                    })
                } else {
                    setAuth(auth);

                    if (search.length > 0) {
                        fetch('/api/content/search/', {
                            method: 'POST',
                            body: JSON.stringify({
                                auth: auth,
                                search: search,
                                page: page
                            })
                        }).then(async response => {
                            const data = await response.json();

                            unstable_batchedUpdates(() => {
                                setData(data);
                                setLoading(false);
                            });
                        }).catch(() => setLoading(false));
                    } else {
                        fetch('/api/content/', {
                            method: 'POST',
                            body: JSON.stringify({
                                auth: auth,
                                page: page
                            })
                        }).then(async response => {
                            const data = await response.json();

                            unstable_batchedUpdates(() => {
                                setData(data);
                                setLoading(false);
                            });
                        }).catch(() => setLoading(false));
                    }
                }
            }).catch(() => setLoading(false));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Box>
                <Box sx={{
                    zIndex: 999,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: isLoading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        fontSize: '64px',
                        color: '#ddc36c',
                        '@keyframes spin': {
                            '0%': {
                                transform: 'rotate(0deg)'
                            },
                            '100%': {
                                transform: 'rotate(360deg)'
                            }
                        },
                        'svg': {
                            animation: 'spin 1.1s linear infinite'
                        }
                    }}>
                        <FontAwesomeIcon icon={faArrowsSpin} />
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                minHeight: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {auth === null ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '250px',
                        '@media screen and (min-width: 340px)': {
                            maxWidth: '300px',
                        },
                        '@media screen and (min-width: 450px)': {
                            maxWidth: '350px',
                        },
                        width: '100%'
                    }}>
                        <TextField sx={{
                            marginBottom: '20px',
                            transition: 'opacity 0.4s',
                            opacity: isLoading ? '0.5 !important' : '1',
                            'input': {
                                '-webkit-text-fill-color': 'unset !important',
                                color: '#e1e6f0 !important'
                            },
                            'label': {
                                color: '#707a94 !important'
                            },
                            'fieldset': {
                                borderRadius: '8px',
                                borderColor: '#707a94 !important'
                            },
                            '.Mui-focused fieldset': {
                                borderColor: '#8f98b2 !important'
                            },
                            'label.MuiInputLabel-shrink': {
                                color: '#8f98b2 !important'
                            }
                        }} label="Email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} disabled={isLoading} />
                        <TextField sx={{
                            marginBottom: '20px',
                            transition: 'opacity 0.4s',
                            opacity: isLoading ? '0.5 !important' : '1',
                            'input': {
                                '-webkit-text-fill-color': 'unset !important',
                                color: '#e1e6f0 !important'
                            },
                            'label': {
                                color: '#707a94 !important'
                            },
                            'fieldset': {
                                borderRadius: '8px',
                                borderColor: '#707a94 !important'
                            },
                            '.Mui-focused fieldset': {
                                borderColor: '#8f98b2 !important'
                            },
                            'label.MuiInputLabel-shrink': {
                                color: '#8f98b2 !important'
                            }
                        }} label="Password" type="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} disabled={isLoading} />
                        <Button sx={{
                            minHeight: '54px',
                            transition: 'transform 0.2s, opacity 0.4s',
                            borderRadius: '8px',
                            alignSelf: 'center',
                            width: '200px',
                            fontSize: '24px',
                            color: '#ffffff !important',
                            textTransform: 'unset',
                            background: 'linear-gradient(93.87deg,#ddc36c,#b19c56)',
                            ':hover': {
                                transform: 'scale(1.04)'
                            },
                            ':active': {
                                transform: 'scale(0.96)'
                            },
                            'span': {
                                display: 'none'
                            },
                            ':disabled': {
                                opacity: 0.2
                            }
                        }} disabled={email.length === 0 || password.length === 0 || isLoading} onClick={() => {
                            setLoading(true);

                            fetch('/api/auth/', {
                                method: 'POST',
                                body: JSON.stringify({
                                    email: email,
                                    password: password
                                })
                            }).then(async response => {
                                let auth = null;

                                if (response.status === 200) {
                                    auth = await response.text();

                                    fetch('/api/content/', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            auth: auth,
                                            page: page
                                        })
                                    }).then(async response => {
                                        const data = await response.json();

                                        unstable_batchedUpdates(() => {
                                            setData(data);
                                            setAuth(auth);
                                            setEmail('');
                                            setPassword('');
                                            setLoading(false);
                                        });
                                    }).catch(() => {
                                        unstable_batchedUpdates(() => {
                                            setEmail('');
                                            setPassword('');
                                            setLoading(false);
                                        });
                                    });
                                } else if (response.status === 401) {
                                    unstable_batchedUpdates(() => {
                                        setEmail('');
                                        setPassword('');
                                        setLoading(false);
                                    });
                                }
                            }).catch(() => {
                                unstable_batchedUpdates(() => {
                                    setEmail('');
                                    setPassword('');
                                    setLoading(false);
                                });
                            });
                        }}>Sign In</Button>
                    </Box>
                ) : (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '326px',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignSelf: 'center',
                            marginBottom: '20px',
                            marginTop: '20px'
                        }}>
                            <TextField sx={{
                                flex: 1,
                                transition: 'opacity 0.4s',
                                width: '280px',
                                opacity: '1',
                                'input': {
                                    WebkitTextFillColor: 'unset !important',
                                    color: '#e1e6f0 !important'
                                },
                                'label': {
                                    color: '#707a94 !important'
                                },
                                'fieldset': {
                                    borderRadius: '8px',
                                    borderColor: '#707a94 !important'
                                },
                                '.Mui-focused fieldset': {
                                    borderColor: '#8f98b2 !important'
                                },
                                'label.MuiInputLabel-shrink': {
                                    color: '#8f98b2 !important'
                                }
                            }} label="Search" autoComplete="off" value={search} onChange={({ currentTarget }) => {
                                unstable_batchedUpdates(() => {
                                    setPage(1);
                                    setSearch(currentTarget.value);
                                });

                                if (currentTarget.value.length > 0) {
                                    fetch('/api/content/search/', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            auth: auth,
                                            search: currentTarget.value,
                                            page: 1
                                        })
                                    }).then(async response => {
                                        setData(await response.json());
                                    }).catch(() => { });
                                } else {
                                    fetch('/api/content/', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            auth: auth,
                                            page: 1
                                        })
                                    }).then(async response => {
                                        setData(await response.json());
                                    }).catch(() => { });
                                }
                            }} />
                        </Box>
                        <Box sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '100%',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                marginBottom: '20px'
                            }}>
                                {data.content.map((_, i) => (
                                    <Box sx={{
                                        position: 'relative',
                                        opacity: data.content[i]['status'] === 1 ? '1' : '0.6',
                                        transition: 'transform 0.2s',
                                        marginRight: '10px',
                                        marginLeft: '10px',
                                        marginBottom: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: 'rgb(20, 20, 20)',
                                        height: 'fit-content',
                                        width: '291.203px',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        boxShadow: 'rgba(0, 0, 0, 0.75) 0px 3px 10px',
                                        cursor: data.content[i]['status'] === 1 ? 'pointer' : 'default',
                                        '*': {
                                            pointerEvents: 'none'
                                        },
                                        ...(data.content[i]['status'] === 1 ? {
                                            ':hover': {
                                                transform: 'scale(1.03)'
                                            },
                                            ':active': {
                                                transform: 'scale(0.95)'
                                            }
                                        } : {})
                                    }} onClick={() => {
                                        if (data.content[i]['status'] === 1) {
                                            navigate(`/edit/${data.content[i]['id']}`, { state: { page: page, search: search } });
                                        }
                                    }}>
                                        <Box sx={{
                                            position: 'relative',
                                            height: '166.969px',
                                            overflow: 'hidden'
                                        }}>
                                            <Box sx={{
                                                width: '100%',
                                                height: '100%'
                                            }} component="img" src={`https://img.arabiflix.com/${data.content[i]['thumb']}`} loading="lazy"></Box>
                                        </Box>
                                        <Box sx={{
                                            padding: '12px'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '0.8em'
                                            }}>
                                                <Box sx={{
                                                    color: 'rgb(188, 188, 188)',
                                                    width: '180px',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}>{data.content[i]['title']}</Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: '#bcbcbc',
                                                    marginLeft: 'auto'
                                                }}>
                                                    <Box sx={{
                                                        fontSize: '14px',
                                                        marginRight: '4px'
                                                    }}>{data.content[i]['views']}</Box>
                                                    <VisibilityIcon sx={{
                                                        height: '16px'
                                                    }} />
                                                </Box>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '14px'
                                            }}>
                                                <Box sx={{
                                                    color: 'rgb(188, 188, 188)',
                                                    marginRight: '0.5em'
                                                }}>{data.content[i]['year']}</Box>
                                                <Box sx={{
                                                    color: 'rgb(188, 188, 188)',
                                                    marginRight: '0.5em'
                                                }}>{data.content[i]['type'] === 'movie' ? formatDuration(data.content[i]['duration']) : data.content[i]['numEpisodes'] === undefined ? `${data.content[i]['numSeasons']} Seasons` : data.content[i]['numEpisodes'] === null ? 'N/A' : `${data.content[i]['numEpisodes']} Episodes`}</Box>
                                                <Box sx={{
                                                    color: 'rgb(225, 230, 240)',
                                                    border: '1px solid rgba(255, 255, 255, 0.4)',
                                                    padding: '0px 0.4rem'
                                                }}>{data.content[i]['maturityRating']}+</Box>
                                            </Box>
                                        </Box>
                                        {data.content[i]['status'] !== 1 ? (
                                            <Box sx={{
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                left: 0,
                                                right: 0,
                                                top: 0,
                                                bottom: 0
                                            }}>
                                                <Box sx={{
                                                    background: data.content[i]['status'] === 0 || data.content[i]['status'] === 3 ? 'black' : 'red',
                                                    color: '#ffffff',
                                                    padding: '8px',
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>{data.content[i]['status'] === 0 ? 'Adding...' : data.content[i]['status'] === 3 ? 'Editing...' : 'Failed'}</Box>
                                            </Box>
                                        ) : null}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Pagination sx={{
                            marginBottom: '40px',
                            '.MuiPaginationItem-root': {
                                color: '#e1e6f0'
                            },
                            '.MuiButtonBase-root': {
                                backgroundColor: 'hsla(0,0%,100%,.2)',
                                color: '#ffffff'
                            },
                            '.MuiButtonBase-root:hover': {
                                backgroundColor: 'hsla(0,0%,100%,.3)'
                            },
                            '.MuiButtonBase-root.Mui-selected': {
                                backgroundColor: '#b19c56 !important'
                            }
                        }} count={data.count} page={page} siblingCount={0} size="large" variant="outlined" shape="rounded" onChange={(_, page) => {
                            setLoading(true);

                            if (search.length > 0) {
                                fetch('/api/content/search/', {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        auth: auth,
                                        search: search,
                                        page: page
                                    })
                                }).then(async response => {
                                    const data = await response.json();

                                    unstable_batchedUpdates(() => {
                                        setData(data);
                                        setPage(page);
                                        setLoading(false);
                                    });
                                }).catch(() => setLoading(false));
                            } else {
                                fetch('/api/content/', {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        auth: auth,
                                        page: page
                                    })
                                }).then(async response => {
                                    const data = await response.json();

                                    unstable_batchedUpdates(() => {
                                        setData(data);
                                        setPage(page);
                                        setLoading(false);
                                    });
                                }).catch(() => setLoading(false));
                            }
                        }} />
                        <Box sx={{
                            paddingBottom: '40px'
                        }}>
                            <Button sx={{
                                minHeight: '54px',
                                transition: 'transform 0.2s, opacity 0.4s',
                                borderRadius: '8px',
                                alignSelf: 'center',
                                fontSize: '24px',
                                color: '#ffffff !important',
                                textTransform: 'unset',
                                background: 'linear-gradient(93.87deg,#ddc36c,#b19c56)'
                            }} onClick={() => navigate('/add', { state: { page: page, search: search } })}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            {data.admin ? (
                                <Button sx={{
                                    marginLeft: '8px',
                                    minHeight: '54px',
                                    transition: 'transform 0.2s, opacity 0.4s',
                                    borderRadius: '8px',
                                    alignSelf: 'center',
                                    fontSize: '24px',
                                    color: '#ffffff !important',
                                    textTransform: 'unset',
                                    background: 'linear-gradient(93.87deg,#34495e,#2c3e50)'
                                }} onClick={() => navigate('/admin', { state: { page: page, search: search } })}>
                                    <FontAwesomeIcon icon={faHammer} />
                                </Button>
                            ) : null}
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default Home;