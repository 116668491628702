import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Select, MenuItem, InputLabel, FormControl, Breadcrumbs, Autocomplete } from "@mui/material";
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsSpin, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';
import { unstable_batchedUpdates } from "react-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';

function formatDuration(milliseconds) {
    let remainingMilliseconds = milliseconds;

    const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
    remainingMilliseconds %= 1000 * 60 * 60;

    const minutes = Math.floor(remainingMilliseconds / (1000 * 60));
    remainingMilliseconds %= 1000 * 60;

    return `${hours}h ${minutes}m`;
}

function EditBillboard() {
    const navigate = useNavigate();
    const { index, type } = useParams();
    const [data, setData] = useState(null);
    const [titles, setTitles] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isLoading, setLoading] = useState(true);
    const [isLoading0, setLoading0] = useState(false);

    console.log(data);

    useEffect(() => {
        fetch('/api/content/billboard/get/', {
            method: 'POST',
            body: JSON.stringify({
                auth: localStorage.getItem('auth'),
                index: parseInt(index),
                type: type === 'movies' ? 'movie' : 'series'
            })
        }).then(async response => {
            const data = await response.json();

            data.image = await new Promise(async resolve => {
                var reader = new FileReader();
                reader.onload = function () {
                    resolve(this.result);
                }

                reader.readAsDataURL(await (await fetch(`https://img.arabiflix.com/${data.image}`)).blob());
            });

            setData(data);
            fetch('/api/content/billboard/titles/', {
                method: 'POST',
                body: JSON.stringify({
                    auth: localStorage.getItem('auth'),
                    type: type === 'movies' ? 'movie' : 'series'
                })
            }).then(async response => {
                const titles = await response.json();

                unstable_batchedUpdates(() => {
                    setTitles(titles);
                    setLoading(false);
                });
            }).catch(() => { });
        }).catch(() => { });
    }, []);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const onImageDrop = acceptedFiles => {
        const reader = new FileReader();

        reader.onload = () => {
            setData({
                ...data,
                image: reader.result
            });
        }

        reader.readAsDataURL(acceptedFiles[0]);
    };

    const imageDropzone = useDropzone({
        onDrop: onImageDrop,
        accept: {
            'image/png': ['.png'],
            'image/webp': ['.webp'],
            'image/jpeg': ['.jpeg', '.jpg']
        }
    });

    return (
        <Box sx={{
            minHeight: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                zIndex: 999,
                margin: '20px 10px',
                padding: '0px 10px',
                fontSize: '41px',
                color: '#e1e6f0',
                alignSelf: 'flex-start',
                cursor: 'pointer'
            }} onClick={() => navigate(`/admin/billboard/${type}`)}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            {isLoading ? (
                <Box sx={{
                    zIndex: 998,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        fontSize: '64px',
                        color: '#ddc36c',
                        '@keyframes spin': {
                            '0%': {
                                transform: 'rotate(0deg)'
                            },
                            '100%': {
                                transform: 'rotate(360deg)'
                            }
                        },
                        'svg': {
                            animation: 'spin 1.1s linear infinite'
                        }
                    }}>
                        <FontAwesomeIcon icon={faArrowsSpin} />
                    </Box>
                </Box>
            ) : (
                <>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        '.billboard': {
                            marginBottom: '10px',
                            width: '100%'
                        },
                        '.banner': {
                            display: 'flex !important',
                            justifyContent: 'center !important'
                        }
                    }}>
                        <Swiper className="billboard" pagination={{
                            clickable: true,
                        }} autoplay={{
                            delay: 4500,
                            disableOnInteraction: false,
                            waitForTransition: true
                        }} centeredSlides={true} modules={[Pagination]} slidesPerView={screenWidth >= 1920 ? 1.2 : screenWidth >= 1280 ? 1.1 : 1} loop={true}>
                            <SwiperSlide className="banner">
                                <Box sx={{
                                    pointerEvents: isLoading0 || isLoading ? 'none' : 'unset',
                                    transition: 'opacity 0.4s',
                                    opacity: isLoading0 || isLoading ? '0.5 !important' : '1',
                                    cursor: 'pointer',
                                    borderRadius: '0px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    backgroundImage: data.image.length === 0 ? 'unset' : 'url(' + data.image + ')',
                                    backgroundPositionY: 'center',
                                    backgroundPositionX: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    width: '100%',
                                    height: '400px',
                                    '@media screen and (max-width: 479px)': {
                                        height: '200px',
                                        ...(() => {
                                            let css = data.css;

                                            try {
                                                css = JSON.parse(css);
                                            } catch {
                                                return {};
                                            }

                                            return css['@media screen and (max-width: 479px)'];
                                        })()
                                    },
                                    '@media screen and (min-width: 960px)': {
                                        height: '400px',
                                        justifyContent: 'unset',
                                        borderRadius: '8px',
                                        border: '1px solid #000000',
                                        ...(() => {
                                            let css = data.css;

                                            try {
                                                css = JSON.parse(css);
                                            } catch {
                                                return {};
                                            }

                                            return css['@media screen and (min-width: 960px)'];
                                        })()
                                    },
                                    '@media screen and (min-width: 1920px)': {
                                        height: '500px',
                                        ...(() => {
                                            let css = data.css;

                                            try {
                                                css = JSON.parse(css);
                                            } catch {
                                                return {};
                                            }

                                            return css['@media screen and (min-width: 1920px)'];
                                        })()
                                    },
                                    ...(screenWidth < 960 ? {
                                        alignItems: 'flex-end',
                                        backgroundPositionY: '0px',
                                        paddingBottom: '45%'
                                    } : {
                                        position: 'relative',
                                        alignItems: 'center',
                                        boxShadow: '0 2px 8px 3px #000000',
                                        margin: '10px'
                                    }),
                                    ...(data.css.length > 0 ? (() => {
                                        try {
                                            const css = { ...JSON.parse(data.css) };
                                            delete css['@media screen and (max-width: 479px)'];
                                            delete css['@media screen and (min-width: 960px)'];
                                            delete css['@media screen and (min-width: 479px)'];
                                            return css;
                                        } catch {
                                            return {};
                                        }
                                    })() : null),
                                    ...(screenWidth < 960 ? {
                                        backgroundSize: 'cover',
                                    } : {})
                                }} {...imageDropzone.getRootProps()}>
                                    <Box component="input" {...imageDropzone.getInputProps()} />
                                    <Box sx={{
                                        borderRadius: '0px',
                                        position: 'absolute',
                                        bottom: -1,
                                        top: -1,
                                        right: -1,
                                        left: -1,
                                        background: 'unset',
                                        justifyContent: 'center',
                                        '@media screen and (min-width: 960px)': {
                                            borderRadius: '8px',
                                            background: 'linear-gradient(180deg,rgba(15,16,20,0) 73.08%,rgba(15,16,20,.01) 77.84%,rgba(15,16,20,.05) 79.74%,#0f1014),linear-gradient(90deg,#0f1014,rgba(15,16,20,.8) 7.31%,rgba(15,16,20,.52) 15.54%,rgba(15,16,20,.12) 29.73%,rgba(15,16,20,0) 40.59%)'
                                        }
                                    }}></Box>
                                    <Box sx={{
                                        borderRadius: '0px',
                                        '@media screen and (min-width: 960px)': {
                                            borderRadius: '8px'
                                        },
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end',
                                        position: 'absolute',
                                        paddingBottom: '20px',
                                        paddingRight: '20px',
                                        bottom: -1,
                                        right: -1,
                                        left: -1,
                                        background: 'linear-gradient(180deg,rgba(15,16,20,0),#0f1014)',
                                        height: screenWidth < 960 ? '100%' : '25%'
                                    }}>
                                        {screenWidth >= 960 ? Array(5).fill(0).map((_, j) =>
                                            <Box key={`bvs${j}`} sx={{
                                                background: j === (index - 1) ? '#ffffff' : '#4b5265',
                                                width: j === (index - 1) ? '7px' : j === (index - 1) - 1 ? '6px' : j === (index - 1) + 1 ? '6px' : '5px',
                                                height: j === (index - 1) ? '7px' : j === (index - 1) - 1 ? '6px' : j === (index - 1) + 1 ? '6px' : '5px',
                                                borderRadius: '50%',
                                                marginLeft: j === 0 ? '0px' : '7.5px'
                                            }}></Box>
                                        ) : null}
                                    </Box>
                                    <Box sx={{
                                        display: data.id.length > 0 ? 'flex' : 'none',
                                        flexDirection: 'column',
                                        zIndex: 999,
                                        alignItems: 'center',
                                        '@media screen and (min-width: 960px)': {
                                            marginLeft: '64px',
                                            maxWidth: '300px',
                                            alignItems: 'unset'
                                        },
                                        '@media screen and (min-width: 1280px)': {
                                            maxWidth: '416px'
                                        },
                                        '@media screen and (min-width: 1920px)': {
                                            maxWidth: '586px'
                                        },
                                        ...(screenWidth < 960 ? {
                                            position: 'absolute',
                                            bottom: 0
                                        } : {}),
                                    }}>
                                        <Box className="logo" sx={{
                                            width: 'fit-content',
                                            maxWidth: '-webkit-fill-available',
                                            zIndex: 999,
                                            marginBottom: '16px',
                                            maxHeight: '148px',
                                            '@media screen and (max-width: 479px)': {
                                                maxHeight: '100px'
                                            },
                                            ...(screenWidth < 960 ? {
                                                maxWidth: '350px'
                                            } : {}),
                                            ...(screenWidth < 560 ? {
                                                maxWidth: '300px'
                                            } : {}),
                                            ...(screenWidth < 360 ? {
                                                maxWidth: '250px'
                                            } : {}),
                                            ...(screenWidth < 330 ? {
                                                maxWidth: '200px'
                                            } : {}),
                                        }} component="img" src={`https://img.arabiflix.com/${data.logo}`}></Box>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                            fontSize: '12px',
                                            background: 'rgb(0 0 0 / 58%)',
                                            borderRadius: '8px',
                                            padding: '8px',
                                            '@media screen and (min-width: 330px)': {
                                                fontSize: '12px'
                                            },
                                            '@media screen and (min-width: 360px)': {
                                                fontSize: '14px'
                                            },
                                            '@media screen and (min-width: 960px)': {
                                                background: 'unset',
                                                borderRadius: 'unset',
                                                padding: 'unset'
                                            },
                                            '@media screen and (min-width: 1280px)': {
                                                fontSize: '16px'
                                            },
                                            '@media screen and (min-width: 1920px)': {
                                                fontSize: '25px'
                                            }
                                        }}>
                                            <Box sx={{
                                                color: '#ffffff',
                                                fontWeight: 600
                                            }}>{data.year}</Box>
                                            <Box sx={{
                                                background: '#bdada3',
                                                borderRadius: '50%',
                                                width: '5px',
                                                height: '5px',
                                                marginLeft: '10px',
                                                marginRight: '10px'
                                            }}></Box>
                                            <Box sx={{
                                                color: '#ffffff',
                                                fontWeight: 600
                                            }}>{data.type === 'series' ? (data.numSeasons > 1 ? `${data.numSeasons} Seasons` : `${data.numEpisodes} Episodes`) : formatDuration(data.duration)}</Box>
                                            <Box sx={{
                                                background: '#bdada3',
                                                borderRadius: '50%',
                                                width: '5px',
                                                height: '5px',
                                                marginLeft: '10px',
                                                marginRight: '10px'
                                            }}></Box>
                                            <Box sx={{
                                                color: '#ffffff',
                                                fontWeight: 600,
                                                background: 'hsla(0,0%,100%,.2)',
                                                borderRadius: '4px',
                                                paddingLeft: '8px',
                                                paddingRight: '8px'
                                            }}>{`${data.maturityRating}+`}</Box>
                                        </Box>
                                        <Box sx={{
                                            display: 'none',
                                            color: 'rgb(255 255 255 / 74%)',
                                            fontSize: '24px',
                                            fontWeight: 'normal',
                                            maxWidth: '480px',
                                            marginBottom: '16px',
                                            WebkitLineClamp: 5,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            '@media screen and (min-width: 960px)': {
                                                display: '-webkit-box',
                                                fontSize: '14px'
                                            },
                                            '@media screen and (min-width: 1280px)': {
                                                fontSize: '16px'
                                            },
                                            '@media screen and (min-width: 1920px)': {
                                                fontSize: '25px'
                                            }
                                        }}>{data.description}</Box>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '12px',
                                            '@media screen and (min-width: 330px)': {
                                                fontSize: '12px'
                                            },
                                            '@media screen and (min-width: 360px)': {
                                                fontSize: '12px'
                                            },
                                            '@media screen and (min-width: 430px)': {
                                                fontSize: '12px'
                                            },
                                            '@media screen and (min-width: 960px)': {
                                                fontSize: '14px'
                                            },
                                            '@media screen and (min-width: 1280px)': {
                                                fontSize: '16px'
                                            },
                                            '@media screen and (min-width: 1920px)': {
                                                fontSize: '25px'
                                            }
                                        }}>
                                            {data.genres.map((genre, j) =>
                                                <Box key={`bnf${j}`} sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <Box sx={{
                                                        textWrap: 'nowrap',
                                                        color: '#ffffff',
                                                        fontWeight: 600
                                                    }}>{genre}</Box>
                                                    {data.genres.length === j + 1 ? null : (<Box sx={{
                                                        background: '#bdada3',
                                                        borderRadius: '50%',
                                                        width: '4px',
                                                        height: '4px',
                                                        marginLeft: '10px',
                                                        marginRight: '10px'
                                                    }}></Box>)}
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </SwiperSlide>
                        </Swiper>
                        {screenWidth < 960 ? (
                            <>
                                <Box sx={{
                                    marginTop: '16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {Array(5).fill(0).map((_, i) =>
                                        <Box key={`jrt${i}`} sx={{
                                            background: i === (index - 1) ? '#ffffff' : '#4b5265',
                                            width: i === (index - 1) ? '7px' : i === (index - 1) - 1 ? '6px' : i === (index - 1) + 1 ? '6px' : '5px',
                                            height: i === (index - 1) ? '7px' : i === (index - 1) - 1 ? '6px' : i === (index - 1) + 1 ? '6px' : '5px',
                                            borderRadius: '50%',
                                            marginLeft: i === 0 ? '0px' : '7.5px'
                                        }}></Box>
                                    )}
                                </Box>
                            </>
                        ) : null}
                    </Box>
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            padding: '40px 20px',
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '950px',
                            width: '100%',
                            alignSelf: 'center'
                        }}>
                            <Autocomplete sx={{
                                marginBottom: '20px'
                            }} options={Object.keys(titles)} filterSelectedOptions getOptionLabel={(option) => titles[option]} {...(data.id.length > 0 ? { value: data.id } : null)} renderInput={(params) => <TextField {...params} sx={{
                                'input': {
                                    WebkitTextFillColor: 'unset !important',
                                    color: '#e1e6f0 !important'
                                },
                                'label': {
                                    color: '#707a94 !important'
                                },
                                'fieldset': {
                                    borderRadius: '8px',
                                    borderColor: '#707a94 !important'
                                },
                                '.Mui-focused fieldset': {
                                    borderColor: '#8f98b2 !important'
                                },
                                'label.MuiInputLabel-shrink': {
                                    color: '#8f98b2 !important'
                                },
                                'svg': {
                                    fill: '#8f98b2 !important'
                                },
                                '.Mui-focused': {
                                    'svg': {
                                        fill: '#8f98b2 !important'
                                    }
                                },
                                '.MuiChip-label': {
                                    color: '#ffffff'
                                },
                                '.MuiChip-root': {
                                    backgroundColor: 'rgb(0 0 0 / 50%)'
                                }
                            }} label="Movie/Show" />} onChange={(_, value) => {
                                fetch('/api/content/billboard/titles/get/', {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        auth: localStorage.getItem('auth'),
                                        id: parseInt(value),
                                        genres: true
                                    })
                                }).then(async response => {
                                    setData({
                                        ...data,
                                        ...(await response.json())
                                    });
                                }).catch(() => { });
                            }} />
                            <TextField sx={{
                                marginBottom: '20px',
                                transition: 'opacity 0.4s',
                                opacity: isLoading0 || isLoading ? '0.5 !important' : '1',
                                transition: 'opacity 0.4s',
                                'textarea': {
                                    height: '200px !important',
                                    WebkitTextFillColor: 'unset !important',
                                    color: '#e1e6f0 !important'
                                },
                                'label': {
                                    color: '#707a94 !important'
                                },
                                'fieldset': {
                                    borderRadius: '8px',
                                    borderColor: '#707a94 !important'
                                },
                                '.Mui-focused fieldset': {
                                    borderColor: '#8f98b2 !important'
                                },
                                'label.MuiInputLabel-shrink': {
                                    color: '#8f98b2 !important'
                                }
                            }} label="CSS" multiline={true} autoComplete="off" autoCorrect="off" spellCheck={false} value={data.css} onChange={({ currentTarget }) => setData({
                                ...data,
                                css: currentTarget.value
                            })} />
                            <Button sx={{
                                padding: '8px 14px',
                                transition: 'opacity 0.4s',
                                opacity: isLoading0 || isLoading ? '0.5 !important' : '1',
                                minHeight: '54px',
                                transition: 'transform 0.2s, opacity 0.4s',
                                borderRadius: '8px',
                                alignSelf: 'center',
                                width: '200px',
                                fontSize: '24px',
                                color: '#ffffff !important',
                                textTransform: 'unset',
                                background: 'linear-gradient(93.87deg,#ddc36c,#b19c56)',
                                ':hover': {
                                    transform: 'scale(1.04)'
                                },
                                ':active': {
                                    transform: 'scale(0.96)'
                                },
                                'span': {
                                    display: 'none'
                                },
                                ':disabled': {
                                    opacity: 0.2
                                }
                            }} disabled={Object.values(data).some(x => x.length === 0) || isLoading || isLoading0} onClick={() => {
                                setLoading0(true);

                                toast.promise(new Promise(async (resolve, reject) => {
                                    let css = data.css; 

                                    if (css.length > 0) {
                                        try {
                                            JSON.parse(css);
                                        } catch {
                                            reject('Invalid CSS');
                                        }
                                    } else {
                                        css = "{}";
                                    }

                                    try {
                                        const response = await fetch('/api/content/billboard/edit/', {
                                            method: 'POST',
                                            body: JSON.stringify({
                                                auth: localStorage.getItem('auth'),
                                                type: type === 'movies' ? 'movie' : 'series',
                                                index: parseInt(index),
                                                image: data.image,
                                                id: parseInt(data.id),
                                                css: css
                                            })
                                        });

                                        if (response.status === 200) {
                                            resolve();
                                        } else {
                                            reject();
                                        }
                                    } catch {
                                        reject();
                                    }

                                    setLoading0(false);
                                }), {
                                    pending: {
                                        type: 'info',
                                        theme: 'dark',
                                        render: 'Processing your request...'
                                    },
                                    success: {
                                        type: 'success',
                                        theme: 'dark',
                                        render: 'Billboard updated successfully.'
                                    },
                                    error: {
                                        type: 'error',
                                        theme: 'dark',
                                        render({ data }) {
                                            return data ? `An error occurred. Unable to update billboard (${data}).` : 'An error occurred. Unable to update billboard.'
                                        }
                                    }
                                });
                            }}>
                                <Box>Edit</Box>
                            </Button>
                        </Box>
                    </Box>
                </>
            )}
            <ToastContainer />
        </Box>
    )
}

export default EditBillboard;
